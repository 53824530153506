.text {
  &__01body, &__02body{
    color: var(--black-color);
  }

  &__03body, &__04body, &__05body {
    color: var(--grey-text-color);
  }

  &__06body{
    color: var(--light-grey-text-color);
  }
}

@include respond-up("medium") {
  .text {
    &__01body{
      font-size: 22px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0em;
    }

    &__02body{
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
    }

    &__03body{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
    }

    &__04body{
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
    }

    &__05body{
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
    }

    &__06body{
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -0.03em;
      text-align: left;
    }
  }
}

@include respond-down("small") {
  .text {
    &__01body{
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
    }

    &__02body{
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0em;
    }

    &__03body{
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
    }

    &__04body{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
    }

    &__05body{
      font-size: 13px;
      font-weight: 500;
      line-height: 17px;
      text-align: left;
    }

    &__06body{
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: -0.03em;
    }
  }
}
