.recall-form {
  position: relative;
  border-radius: 6px;
  &._long-recall-form{
    .recall-form{
      &__field-name{
        display: none;
      }
    }

    input{
      background: var(--grey-background) !important;
      color: #000000 !important;
      &::placeholder {
        color: var(--grey-text-color) !important;
      }
    }
  }

  &._hero{
    background: #00B7CD;
    padding: 16px;
    border-radius: 8px;

    input{
      background: #FFFFFF !important;
      color: #000000 !important;
      &::placeholder {
        color: var(--grey-text-color) !important;
      }
    }
  }

  &:before{
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
  }

  &__button-wrap {
    button {
      border: unset;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 145%; /* 21.75px */
      color: #FFFFFF;
    }
  }

  &__data {
    z-index: 10;
  }

  &__icon {
    display: flex;
    position: absolute;
    z-index: 5;

    //svg {
    //  width: 100%;
    //  height: 100%;
    //}

    top: 0;
    bottom: 0;
    left: 0;
    svg{
      height: 100%;
      width: 100%;
    }
  }

  &__heading {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
    }
  }

  &__title,
  &__description {
    color: #fff;
    transition: opacity .3s;
  }

  &__main,
  &__bottom {
  }

  &__button-wrap {
    display: flex;
    flex: 0 0 auto;
  }

  &__policy {
    flex: 0 1 auto;
  }

  &__success {
    display: flex;
    align-items: center;
    height: 100% !important;
    justify-content: center;
  }

  &__success-wrap{
    display: flex;
    align-items: center;
  }

  &__success-icon {
    display: flex;
    border-radius: 100%;
    overflow: hidden;
  }

  &__success-title {
    color: #fff;
  }

  &__success-description {
    margin-top: 12px;
    color: #fff;
  }
}

@include respond-up('s-large') {
  .recall-form {
    &._long-recall-form{
      form{
        display: grid !important;
        grid-template-columns: 247px 247px;
      }

      .recall-form{
        &__fields{
          display: flex;
        }
        &__field{
          width: 100%;
        }

        &__success{
          justify-content: left;
        }

        &__success-icon{
          svg{
            rect{
              fill: var(--primary-blue);
            }
            path{
              stroke: #FFFFFF;
            }
          }
        }

        &__success-title{
          color: var(--black-color);
          max-width: 343px;
        }
      }
    }

    form{
      display: grid;
      grid-template-columns: 4fr 1fr;
      align-items: center;
    }
    &__form-wrap {
      //flex: 0 1 100%;
    }

    &__icon {
      &_top {
        left: 0;
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
    }

    &__heading {
      padding: 50px 0 30px;
      grid-column: 2 / 7;

      &::before {
        left: 75px;
        right: 75px;
      }
    }

    &__inner{
      grid-column: 7 / 12;
    }


    &__fields{
      display: grid;
      /* justify-content: space-between; */
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 4px;
    }

    &__field{
      border-radius: 6px;
    }

    &__success-icon {
      margin-right: 20px;
    }

    &__button{
      margin-left: 4px;
    }
  }
}

@include respond-up('medium') {
  .recall-form {

    &__success {
      //padding-top: 30px;
    }

    &__policy{
      //width: 275px;
      margin-top: 12px;
    }

    &__button-wrap {

    }
  }
}

@include respond-down('medium') {
  .recall-form {
    &._long-recall-form{
      .recall-form{
        &__button-wrap{
          width: 100%;
          div{
            display: flex;
            width: 100%;
          }
        }

        &__success{
          justify-content: left;
        }

        &__success-icon{
          svg{
            rect{
              fill: var(--primary-blue);
            }
            path{
              stroke: #FFFFFF;
            }
          }
        }

        &__success-title{
          color: var(--black-color);
          max-width: 343px;
        }
      }
    }
    &__heading {
      grid-column: 1 / 5;
      padding: 20px 20px 30px;
    }

    &__title{
      padding-bottom: 8px;
    }



    &__inner{
      grid-column: 1 / 5;
      padding: 0 20px;
    }

    &__data {
      width: 100%;
    }

    &__form-wrap{
      width: 100%;
    }

    &__fields{
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 16px;
    }

    &__button-wrap{
      flex: 1 1 100%;
      div{
        width: 100%;
      }
      button{
        width: 100%;
      }

      display: flex;
      margin-top: 10px;
    }

    &__bottom{
      display: flex;
    }

    &__policy{
      .policy-checkbox__inner {
        text-align: left;
      }
    }

    &__success{
      padding: 0 25px;
      align-items: center;
    }

    &__success-icon{
      margin-bottom: 16px;
    }

    &__success-wrap{
      align-items: center;
      text-align: center;
      flex-direction: column;
    }

    &__description {
      font-size: 15px;
      line-height: 160%;
      font-weight: 400;
    }
  }
}