h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;
  font-style: normal;
}

h1, .h1, h2, .h2, h3, .h3 {
  font-weight: 600;
  line-height: 100%;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 500;
  line-height: 130%;
}

@include respond-up("s-large") {
  h1, .h1 {
    --h-size: clamp(0px, calc((120 / 1920) * 100vw), 55px);
  }

  h2, .h2 {
    --h-size: clamp(0px, calc((60 / 1920) * 100vw), 33px);
  }

  h3, .h3 {
    --h-size: clamp(0px, calc((40 / 1920) * 100vw), 20px);
  }

  h4, .h4 {
    --h-size: 17px;
  }

  h5, .h5 {
    --h-size: 16px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond-down("medium") {
  h1, .h1 {
    --h-size: 32px;
  }

  h2, .h2 {
    --h-size: 24px;
  }

  h3, .h3 {
    --h-size: 18px;
  }

  h4, .h4 {
    --h-size: 16px;
  }

  h5, .h5 {
    --h-size: 14px;
  }

  h6, .h6 {
    --h-size: 14px;
  }
}