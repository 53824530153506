.products-slider {
  &__item{
    &.swiper-slide{
      height: auto;
    }
  }
}
@include respond-down('medium') {
  .products-slider{

  }
}
@include respond-down('small') {
  .products-slider {
    &.swiper{
      padding-right: calc(var(--grid-indent) + var(--grid-spacer));
      overflow: unset;
      width: auto;
    }

    &__item{
      &:first-child{
        margin-left: calc(var(--grid-indent) + var(--grid-spacer));
      }

      &:last-child{
        margin-right: calc(var(--grid-indent) + var(--grid-spacer)) !important;
      }
    }
  }
}