.hero {
  &__image-container{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: linear-gradient(0deg, rgba(0, 9, 31, 0.6), rgba(0, 9, 31, 0.6));
    }
  }

  &__picture{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__relative-container{
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__text-container{
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    color: #ffffff;
    height: 100%;
    justify-content: left;
    align-content: center;
  }

  &__title{
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: -0.02em;
  }

  &__subtitle{
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 135%;
    letter-spacing: -0.02em;
  }

  &__button{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
    max-width: fit-content;
    padding-bottom: 8px;
  }

  &__title{
    grid-row: 2 / 3;
  }

  &__subtitle{
    grid-row: 3 / 4;
  }

  &__button{
    grid-row: 4 / 5;
  }

  &__offer{
    background: var(--white-opacity-20);
    padding: 4px 19px;
    width: fit-content;
  }
}

@include respond-up('s-large') {
  .hero {
    padding-top: 98px;
    &__image-container, &__relative-container{
      height: 560px;
    }

    &__text-container{
      width: var(--grid-column8);
    }

    &__offer{
     margin-bottom: 24px;
    }

    &__title, &__subtitle{
      max-width: 740px;
    }

    &__title{
      margin-bottom: 20px;
    }

    &__subtitle{
      margin-bottom: 40px;
    }
  }
}

@include respond-down('medium') {
  .hero {
    &__image-container{
      height: 120vw;
      max-height: 400px;
    }

    &__relative-container{
      //height: calc(min(100vw, 560px));
    }

    &__text-container{
      display: flex;
      flex-direction: column;
      max-width: 450px;
      height: auto;
    }

    &__offer{
      margin-top: 40px;
      height: fit-content;
    }

    &__title{
      padding-top: 14px;
      font-size: calc(min(8vw, 40px));
    }

    &__title-container{
      display: flex;
      flex-direction: column;
    }

    &__subtitle{
      padding-top: 14px;
      margin-bottom: 40px;
      font-size: calc(min(4vw, 20px));
      max-width: 500px;
    }

    &__button{
      padding-top: 33px;
      font-size: 13px;
      grid-column: 1 / 5;
    }
  }
}

@media (max-width: 1199px) {
  .hero{
    padding-bottom: 120px;
  }
}

@media (max-width: 500px) {
  .hero{
    //padding-bottom: 20vw;
  }
}

