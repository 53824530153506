.map{
  position: relative;
  &__inner{
    position: absolute;
    background: #FFFFFF;
    z-index: 100;
    border-top: 6px solid var(--primary-blue);
    border-radius: 8px;
    overflow: hidden;
  }
}

@include respond-up('s-large') {
  .map{
    &__element{
      height: 532px;
    }
    &__inner{
      width: 360px;
      //left: calc(var(--grid-spacer) + var(--grid-indent));
      top: 40px;
      padding: 30px;
    }

    &__title{
      margin-bottom: 20px;
    }
  }
}

@include respond-down('medium') {
  .map{
    padding-top: 54px;
    &__element{
      height: 471px;
    }

    &__inner{
      border-top: 4px solid var(--primary-blue);
      left: 30px;
      right: 40px;
      top: 0;
      padding: 30px;
      background: var(--grey-background);
    }

    &__title{
      margin-bottom: 14px;
    }
  }
}