.product{
  background: #FFF;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__button{
    display: flex;
    justify-content: center;
  }
}

@include respond-up('s-large') {
  .product{
    &__image-container{
      position: relative;
      padding-bottom: 11vw;
    }

    &__info{
      padding: 20px 15px 15px;
    }
    &__name{
      height: 66px;
      margin-bottom: 13px;
      &.h4{
        font-size: 17px !important;
      }
    }
    &__price-text{
      &.h4{
        font-size: 17px !important;
      }
    }

    &__button{
      margin-top: 14px;
    }
  }
}
@include respond('medium') {
  .product{
    &__image-container{
      position: relative;
      padding-bottom: 21vw;
    }
  }
}
@include respond-down('medium') {
  .product{
    &__info{
      padding: 20px 15px 15px;
    }
    &__name{
      height: 66px;
      margin-bottom: 13px;
      &.h4{
        font-size: 17px !important;
      }
    }
    &__price-text{
      &.h4{
        font-size: 17px !important;
      }
    }

    &__button{
      margin-top: 14px;
    }
  }
}

@include respond-down('small') {
  .product{
    &__image-container{
      position: relative;
      padding-bottom: 41vw;
    }
  }
}