.reviews{
  &__review-top{
    display: flex;
    align-items: center;
    position: relative;
  }

  &__arrows{
    .slider-arrow{
      background: var(--grey-background);
      &:hover{
        background: var(--primary-blue);
      }
    }
  }

  &__review-top-image{
    display: flex;
  }

  &__review-top-text{
    display: flex;
    flex-direction: column;
  }

  &__review-name{
    margin-bottom: 2px;
  }

  .swiper{
    .swiper-slide{
      opacity: 0;
      transition: opacity .3s;
    }

    .swiper-slide-active{
      opacity: 1;
    }
  }
}

@include respond-up('s-large') {
  .reviews{
    padding: 80px 0;
    &__wrap{
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      position: relative;
    }

    &__review-top-image{
      margin-right: 20px;
    }

    &__control-wrap{
      //flex: 0 0 var(--grid-column3);
      grid-column: 1 / 4;
    }

    &__arrow-wrap{
      position: absolute;
      left: calc(var(--grid-spacer) + var(--grid-indent));
      top: 80px;
    }

    &__container{
      //flex: 0 0 var(--grid-column9);
      //width: var(--grid-column9);
      grid-column: 5 / 13;
    }

    &__review-bottom{
      margin-top: 20px;
      display: block;
    }

    &__pagination{
      position: absolute;
      top: 7px;
      right: 0;
    }
  }
}

@include respond-down('medium') {
  .reviews{
    padding: 40px 0;

    &__wrap{
      position: relative;
    }

    &__review-top-image{
      margin-right: 14px;
    }

    &__title{
      padding-bottom: 28px;
    }

    &__arrow-wrap{
      position: absolute;
      right: calc(var(--grid-spacer) + var(--grid-indent));
      top: -8px;
    }

    &__pagination{
      position: absolute;
      top: 7px;
      right: 0;
    }

    &__review-bottom{
      margin-top: 20px;
      display: block;
    }
  }
}