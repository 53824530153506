article {
  > * {
    &:first-child {
      margin-top: 0;
    }
  }

  * > p {
    &:first-child {
      margin-top: 0;
    }
  }

  &, p, li, a {
    // text__03body
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }

  ul, ol, p {
    margin-top: 15px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    border-bottom: 1px solid;
  }

  ul, ol {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  ul {
    list-style: none;

    li {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 5px;
        height: 5px;
        background: #000;
        border-radius: 50%;
      }
    }
  }

  ol {
    counter-reset: counter;

    li {
      counter-increment: counter;

      &::before {
        content: counter(counter) '.';
        position: absolute;
        left: 0;
        top: 4px;
        line-height: 100%;
      }
    }
  }

  li {
    position: relative;
  }
}

@include respond-up('s-large') {
  article {
    li {
      padding-left: 30px;
    }

    a {
      transition: color var(--default-timing) var(--default-transition-function);
      &:hover {
        color: var(--blue-hover);
      }
    }
  }
}

@include respond('medium') {
  article {
    li {
      padding-left: 20px;
    }
  }
}

@include respond-down('small') {
  article {
    &, p, li, a {
      font-size: 15px;
      line-height: 23px;
    }

    li {
      padding-left: 15px;
    }
  }
}