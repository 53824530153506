.products {
  background: var(--grey-background);
  overflow: hidden;
  &__body{
    position: relative;
  }

  &__title-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title{
    display: block;
    @extend h2;
  }

  &__arrow-wrap{
    position: absolute;
    right: 0;
  }

  &__arrows {
    &._hidden {
      display: none;
    }
  }

  &__pagination{
    display: flex;
    height: 100%;
    justify-content: center;

    .swiper-pagination-bullet{
      margin-right: 8px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: #C6D5DE;
      &.swiper-pagination-bullet-active{
        background: var(--primary-blue);
      }
    }
  }
}

@include respond-up('s-large') {
  .products {
    &__title{
      margin-bottom: 35px;
    }
    &__arrow-wrap{
      top: -77px;
    }

    &__pagination{
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .products {
    &__wrap{
      //margin-right: calc(var(--grid-indent) + var(--grid-spacer));
      //margin-left: calc(var(--grid-indent) + var(--grid-spacer));
    }
    &__head {
      .container_spacer {
        padding: 0;
      }
    }
  }
}

@include respond('medium') {
  .products {
    &__title{
      margin-bottom: 28px;
    }

    &__arrow-wrap{
      top: -71px;
    }
  }
}

@include respond-down('medium') {
  .products {
    &__pagination{
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .products {
    &__title{
      margin-bottom: 20px;
    }

    &__arrow-wrap{
      display: none;
    }
  }
}