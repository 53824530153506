.index-page{

}

@include respond-up('s-large') {
  .index-page{
    &__under-hero{
      padding-bottom: 80px;
    }

    &__products{
      padding: 80px 0;
    }
  }
}

@include respond-down('medium') {
  .index-page{
    &__under-hero{
      padding-bottom: 40px;
    }

    &__products{
      padding: 40px 0;
    }
  }
}