.how{
  background: var(--grey-background);
  &__title{
    display: block;
  }

  &__item-number{
    color: var(--primary-blue);
  }

  &__item-line{
    display: block;
  }

  &__item-top{
    display: flex;
  }
}

@include respond-up('s-large') {
  .how{
    padding: 80px 0;
    &__title{
      margin-bottom: 40px;
    }

    &__list{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 32px;
    }

    &__item{
      &:nth-child(-n+3) {
        .how{
          &__item-line{
            &:before{
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              background: var(--primary-blue);
              top: calc(50% - 1px);
            }
          }

          &__item-icon{
            display: none;
          }
        }
      }

      &:nth-child(n+4) {
        .how{
          &__item-line{
            display: none;
          }

          &__item-top{
            display: flex;
            align-items: center;
          }
        }
      }
    }

    &__item-top{
      margin-bottom: 20px;
    }

    &__item-bottom{
      max-width: 225px;
    }

    &__item-number{
      margin-right: 34px;
    }

    &__item-title{
      margin-bottom: 8px;
    }

    &__item-line{
      flex: 1 1 100%;
      position: relative;
    }

    &__button{
      margin-top: 20px;
      &_small{
        display: none;
      }
    }
  }
}

@include respond-down('medium') {
  .how{
    padding: 40px 0;

    &__title{
      margin-bottom: 25px;
    }

    &__list{
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 14px;
    }

    &__item{
      padding-left: 20px;
      display: flex;
      &:nth-child(n+2) {
        .how{
          &__button{
            display: none;
          }
        }
      }

      &:nth-child(-n+3) {
        .how{
          &__item-line{
            &:before{
              content: '';
              position: absolute;
              height: 100%;
              width: 1px;
              background: var(--primary-blue);
              left: calc(50% - 1px);
            }
          }

          &__item-icon{
            display: none;
          }
        }
      }

      &:nth-child(n+4) {
        .how{
          &__item-line{
            display: none;
          }

          &__item-top{
            display: flex;
            align-items: center;
          }

          &__item-number{
            margin-bottom: 8px;
          }
        }
      }
    }

    &__item-top{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 31px;
    }

    &__item-bottom{
      max-width: 225px;
      padding-bottom: 16px;
    }

    &__item-number{
      margin-bottom: 14px;
    }

    &__item-title{
      margin-bottom: 8px;
    }

    &__item-line{
      flex: 1 1 100%;
      position: relative;
    }

    &__button{
      margin-top: 14px;
      &_large{
        display: none;
      }
    }
  }
}