:root{
  --modal-bg: rgba(0, 0, 0, 0.76);
  --modal-content-radius: 5px;
  --modal-content-shadow: 3px 4px 35px rgba(92, 99, 100, 0.27);
  --modal-content-background: #fff;
  --modal-closer-color: var(--modal-bg);
  --modal-closer-size: 30px;
  --modal-preloader-size: 30px;
}

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: var(--modal-bg);
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &._opened {
    display: block;
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  padding: 80px 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &._opened {
    display: flex;
  }
}

.modal__container {
  padding: var(--modal-content-padding);
  border-radius: var(--modal-content-radius);
  background: var(--modal-content-background);
  box-shadow: var(--modal-content-shadow);
  position: relative;
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: absolute;

  font-size: var(--modal-closer-size);
  color: var(--modal-closer-color);

  border-radius: 100%;

  text-decoration: none;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--modal-bg);
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

.modal__heading-title {
  text-align: center;
}

body.modal-loading {
  overflow: hidden;
  --primary-color-opacity: rgba(1, 199, 103, .85);
  --preloader-size: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: var(--preloader-size);
      height: var(--preloader-size);
      left: 50%;
      top: 50%;
      margin-left: calc(var(--preloader-size)/-2);
      margin-top: calc(var(--preloader-size)/-2);
      background-color: #000;
      border-radius: calc(var(--preloader-size)/2);
      transform: scale(0);
    }

    &:before {
      background-color: var(--primary-color-opacity, var(--primary-color));
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: var(--primary-color);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}

@include respond-up('s-large') {
  :root {
    --modal-content-padding: 35px 40px 45px 40px;
  }

  .modal__content {
    max-width: calc(var(--grid-column6) + var(--grid-gap) * 2);
  }

  .modal__closer {
    top: 0;
    right: -68px;
    width: 48px;
    height: 48px;
    //border: 1px solid #fff;
    background: var(--white-opacity-20);

    &::before {
      width: 24px;
      height: 24px;
      background-image: url('../images/svg/closer.svg')
    }
  }
}

@include respond('medium') {
  :root {
    --modal-content-padding: 40px 60px 60px;
  }

  .modal__content {
    max-width: calc(var(--grid-column6) - 56px);
  }

  .modal__closer {
    top: 21px;
    right: 21px;
  }
}

@include respond-down('medium') {

}

@include respond-down('small') {
  :root {
    --modal-content-padding: 0 20px 0;
  }

  .modal__closer {
    width: 40px;
    height: 40px;
    background: var(--grey-background);
    &::before {
      width: 22px;
      height: 22px;
      background-image: url('../images/svg/closer-blue.svg');
      background-size: contain;
    }
  }

  .modal__content {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal__layout {
    padding: 0;
  }

  .modal__container {
    width: 100vw;
    &._technology {
      padding: 0;

      .modal__content {
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .modal-form-heading {
        padding: 18px;
        flex: 0 0 auto;
      }
    }
  }

  .modal__closer {
    width: 34px;
    height: 34px;
    top: 14px;
    right: 11px;

    &::before {
      width: 18px;
      height: 18px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}