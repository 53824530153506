.slider-arrow {
  border-radius: 6px;
  background: #FFF;
  transition: var(--default-transition);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &_white {
    background: #fff;
  }

  &_light-grey {
    background: var(--grey-bg-lighter);
  }

  svg path {
    fill: var(--grey-text);
    transition: var(--default-transition);
  }

  &_left {
    svg {
      transform: rotate(180deg);
    }
  }

  &_white-40 {
    background: var(--white-color-opacity40);
    svg path {
      fill: #fff;
    }
  }
}

@include respond-up('s-large') {
  .slider-arrow {
    &:hover {
      background: var(--primary-blue);
      svg path {
        stroke: #FFF;
      }
    }
  }
}

@include respond-up('medium') {
  .slider-arrow {
    width: 50px;
    height: 50px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@include respond-down('medium') {
  .slider-arrow{
    &:hover {
      background: var(--primary-blue);
      svg path {
        stroke: #FFF;
      }
    }
  }
}

@include respond-down('small') {
  .slider-arrow {
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
