.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4700;

  &__logo{
    display: flex;
    align-items: center;
  }

  &__logo-text{
    font-size: 18px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-left: 14px;
  }

  &__geo-text, &__time-text{
    color: #FFF;
    margin-right: 40px;
  }

  &__geo-icon, &__time-icon{
    display: flex;
    margin-right: 14px;
  }

  &__phone{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #2A2A2F;
  }

  &__button{
  }

  &__recall-link{

  }

  &__top{
    background: var(--dark-blue);
  }

  &__bottom{
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
  }

  &__recall-link{
  }
}

@include respond-up('s-large') {
  .header{
    transition: transform .5s;
    &._sticky{
      position: sticky;
      top: 0;
      transform: translateY(-60px);
    }

    &__wrap {
      position: relative;
      width: 100%;
    }

    &__top-wrap{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__top{
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__contact-wrap, &__time-info, &__geo-info{
      display: flex;
      align-items: center;
    }

    &__recall-link{
      &_mobile{
        display: none;
      }
    }

    &__bottom{
      position: absolute;
      left: 0;
      right: 0;
      height: 97px;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 135%;
      letter-spacing: -0.02em;
      color: #2A2A2F;
      display: flex;
      align-items: center;
      transition: opacity .2s;
      background-color: #FFFFFF;
      opacity: 1;
      pointer-events: all;
    }

    &__bottom-right{
      display: flex;
      align-items: center;
    }

    &__links{
      display: flex;
      align-items: center;
    }

    &__bottom-wrap{
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &__menu{
      display: flex;
      align-items: center;
      align-self: center;
    }

    &__menu-link{
      padding: 37px 20px;
      transition: color .3s;
      &:hover{
        color: var(--primary-blue);
      }
    }

    &__drop-menu{
      display: none;
    }

    &__hamburger-wrap{
      display: none;
    }

    &__button{
      transition: background-color .3s;
      &:hover{
        background: #041D47;
      }
    }

    &__recall{
      margin-left: calc(30/1440 * 100vw);
      .fast-recall__phone{
        color: var(--black-color);
      }
    }

    &__recall-link{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: #00B7CD;
    }

    &__add-link{
      display: flex;
    }

    &__drop-link{
      cursor: pointer;
      min-width: 150px;
      max-width: 197px;
      width: calc(197 / 1450 * 100vw);
      padding: 38px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-right: 1px var(--grey-line) solid;
      &:first-child{
        border-left: 1px var(--grey-line) solid;
      }
      position: relative;

      &:hover{
        .header{
          &__drop-link-icon{
            transform: rotate(180deg);
          }
          &__bottom-drop-menu{
            opacity: 1;
            pointer-events: all;
          }
        };
      }
    }

    &__drop-link-icon{
      display: flex;
      transition: transform .3s;
    }

    &__bottom-drop-menu{
      opacity: 0;
      transition: opacity .3s;
      pointer-events: none;
      position: absolute;
      padding-top: 6px;
      top: 98px;
      left: 0;
    }

    &__bottom-drop-inner{
      background: #FFF;
      width: max-content;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      padding: 20px 16px;
      box-shadow: 0 20px 40px -15px #181A2C1F;
    }

    &__inner-drop-link{
      white-space: nowrap;
      padding: 8px 14px;
      transition: background .3s, color .3s;
      background: transparent;
      color: var(--black-color);
      border-radius: 4px;

      &:hover{
        background: var(--grey-background);
        color: var(--primary-blue);
      }
    }

    &__top-mobile{
      display: none;
    }
  }
}

@include respond-down('medium'){
  .header{
    transition: transform .3s;
    &._sticky{
      transform: translateY(-58px);
    }
    &__drop-menu{
      position: absolute;
      top: 110px;
      left: 0;
      right: 0;
      opacity: 0;
      width: 100%;
      height: calc(100vh - 52px);
      z-index: 1000;
      pointer-events: none;
      transition: opacity .4s;
      overflow-y: scroll;
      &._opened{
        position: fixed;
        opacity: 1;
        pointer-events: all;
      }
    }
    &__top-wrap{
      height: 58px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }

    &__bottom-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 52px;
      background: #FFFFFF;
    }

    &__bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__contact-wrap{
      display: none;
    }
    &__logo{
      padding: 9px 0;
      svg{
        width: 22px;
        height: 30px;
      }
    }
    &__logo-text{
      font-size: 14px;
      margin-left: 10px;
    }

    &__top-mobile{
      width: 89px;
      color: #FFFFFF !important;
      padding-left: 10px;
      border-left: 2px solid var(--primary-blue);
      margin-left: 10px;
    }

    &__links{
      display: none;
    }

    &__menu{
      display: none;
    }

    &__menu-text{
      margin-left: 14px;
    }

    &__hamburger-wrap{
      display: flex;
      align-items: center;
      height: 100%;
      padding-right: 13px;
      border-right: 1px solid var(--grey-line);
    }

    &__recall{
      flex-direction: row-reverse;
      display: flex;
      .fast-recall{
        &__contact-wrap{

        }
        &__phone-subtitle{
          display: none;
        }
        &__whatsapp-logo{
          margin-right: 0;
          margin-left: calc(14/ 378 * 100vw);
          svg{
            width: 25px;
            height: 25px;
          }
        }
        &__phone{
          color: var(--black-color) !important;
          font-size: clamp(16px, 4vw, 22px);
        }
      }
    }
  }
}