.policy-checkbox {
  position: relative;

  input {
    position: absolute;
    width: 22px;
    height: 22px;

    &:checked + label {
      &:before {
        background-image: url("../images/svg/checkbox-checked.svg");
      }
    }
  }

  label {
    padding-left: 32px;
    width: 100%;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      background-image: url("../images/svg/checkbox-unchecked.svg");
    }
  }

  &__policy-link {
    color: var(--primary-red);
  }

  &__error {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: calc(100% + 12px);
    background-color: #fff;
    padding: 8px 10px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s var(--default-transition-function);

    &::before {
      content: '';
      position: absolute;
      left: 4px;
      top: -4px;
      width: 8px;
      height: 8px;
      background-color: #fff;
      transform: rotate(45deg);
    }

    &._visible {
      opacity: 1;
    }
  }

  &__error-title {
    margin-left: 8px;
    color: var(--primary-color);
  }

  &_dark {
    input {
      &:checked + label {
        &:before {
          background-image: url("../images/svg/checkbox-checked.svg");
        }
      }
    }

    label {
      color: var(--grey-text-color);

      &:before {
        background-image: url("../images/svg/checkbox-unchecked.svg");
      }
    }

    .policy-checkbox {
      &__policy-link {
        color: var(--grey-text-color);
      }
    }
  }
}

@include respond-up('s-large') {
  .policy-checkbox {
    &__policy-link {
      border-bottom: 1px solid var(--t);
      transition: border-bottom-color .3s;

      &:hover {
        border-bottom-color: var(--grey-text-color);
      }
    }

    &_dark {
        label{
          color: #fff !important;
        }
        .policy-checkbox{
          &__policy-link {
            color: #fff !important;
            &:hover {
              border-bottom-color: #FFF;
            }
          }
        }

    }
  }
}

@include respond-down('small') {
  .policy-checkbox{
    margin-top: 12px;
    &__inner{
      text-align: center;
    }

    &__policy-link{
      border-bottom: 1px solid var(--grey-text-color);

    }
  }
}
