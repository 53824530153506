.modal-form-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  &__title {
    margin-bottom: 20px;
    text-align: center;
  }
  &__icon{
    margin-bottom: 20px;
    svg{
      rect{
        fill: var(--primary-blue);
      }
      path{
        stroke: #FFFFFF;
      }
    }
  }
}

