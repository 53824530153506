.button {
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  display: inline-block;
  white-space: nowrap;
  &_dark{
    background: var(--dark-blue);
    color: #FFF;
    &:hover{
      background: var(--dark-blue-hover);
    }
  }

  &_grey{
    background: var(--grey-background);
    color: var(--primary-blue);
    &:hover{
      background: var(--primary-blue);
      color: #FFF;
    }
  }

  &_blue{
    background: var(--primary-blue);
    color: #FFF;
    &:hover{
      background: var(--blue-hover);
    }
  }

  &_line{
    border: 1px solid var(--primary-blue);
    color: var(--primary-blue);
    &:hover{
      background: var(--primary-blue);
      color: #FFF;
    }
  }
}

@include respond-up('s-large') {
  .button{
    padding: 19px 34px;

    &_line{
      padding: 8px 18px;
    }
  }
}

@include respond-down('medium') {
  .button{
    padding: 14px 24px;

    &_line{
      padding: 8px 18px;
    }
  }
}