.seo-block{
  background: #FFF;
  &__title{
    @extend h2;
  }

  &__subtitle{
    @extend .text__03body;
  }
}

@include respond-up('s-large') {
  .seo-block{
    padding: 80px 0;

    &__title{
      margin-bottom: 20px;
    }
  }
}

@include respond-down('medium') {
  .seo-block{
    padding: 40px 0;
    &__title{
      margin-bottom: 14px;
    }
  }
}