.under-hero{
  &__title{
    display: block;
  }

  &__subtitle{
    @extend .text__01body
  }

  &__text{
    //@extend .text__03body
  }

  &__side-top, &__side-bottom{
    border-radius: 8px;
  }

  &__side-top{
    background: var(--grey-background);
  }

  &__side-bottom{
    background-color: var(--primary-blue);
    position: relative;
    overflow: hidden;
    &:before{
      content: '';
      height: 100%;
      width: 5px;
      background: var(--dark-blue);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__side-bottom-text, &__why-item-title, &__side-title{
    @extend h3;
  }

  &__side-bottom-text{
    &_dark{
      color: var(--black-color);
    }

    &_light{
      color: #FFF;
    }
  }

  &__side-subtitle{
    @extend .text__04body;
  }

  &__why-title{
    @extend h2;
  }

  &__why-item{
    display: flex;
    align-items: center;
    position: relative;
  }

  &__why-item-wrap{
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  &__icon{
    display: flex;
  }
}

@include respond-up('s-large') {
  .under-hero{
    padding-top: 80px;
    &__grid-wrap{
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    }
    &__text-wrap{
      grid-column: 1 / 9;
    }

    &__side{
      grid-column: 10 / 13;
    }

    &__title{
      margin-bottom: 20px;
    }

    &__subtitle{
      margin-bottom: 40px;
    }

    &__side-top{
      padding: 20px 20px 30px;
      margin-bottom: 14px;
    }

    &__side-bottom{
      padding: 20px;
      display: flex;
      flex-direction: column;
    }

    &__side-icon{
      margin-bottom: 20px;
    }

    &__side-title{
      margin-bottom: 10px;
      max-width: 220px;
      font-size: 20px !important;
      line-height: 24px !important;
    }

    &__side-subtitle{
      max-width: 220px;
    }

    &__side-bottom-text{
      font-size: 20px !important;
      line-height: 24px !important;
    }

    &__why{
      margin-top: 80px;
    }

    &__why-title{
      margin-bottom: 40px;
    }

    &__why-list{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 50px;
    }

    &__icon{
      margin-right: 40px;
    }

    &__why-item{
      padding-left: 30px;
      &:nth-child(1), &:nth-child(2){
        padding-bottom: 22px;
      }

      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: var(--grey-line);
      }
    }

    &__why-item-title{
      margin-bottom: 8px;
    }
  }
}

@include respond-down('medium') {
  .under-hero{
    &__text-wrap, &__side{
      grid-column: 1 / 5;
    }

    &__grid-wrap{
      grid-row-gap: 30px;
    }

    &__title{
      margin-bottom: 14px;
    }

    &__subtitle{
      margin-bottom: 20px;
    }

    &__icon{
      margin-right: 28px;
    }

    &__why-item-title{
      margin-bottom: 6px;
    }

    &__side-top{
      padding: 16px;
      margin-bottom: 14px;
    }

    &__side-bottom{
      padding: 16px;
    }

    &__side-icon{
      margin-bottom: 16px;
    }

    &__side-title{
      margin-bottom: 6px;
      font-size: 18px !important;
      line-height: 21px !important;
    }

    &__side-bottom-text{
      font-size: 18px !important;
      line-height: 21px !important;
    }

    &__why{
      margin-top: 40px;
    }

    &__why-title{
      margin-bottom: 25px;
      font-size: 18px !important;
      line-height: 21px !important;
    }

    &__why-list{
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 25px;
    }

    &__why-item{

    }

    &__why-item-title{
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 1199px) {
  .under-hero{
    padding-top: 120px;
  }
}

@media (max-width: 600px) {
  .under-hero{
    padding-top: 25vw;
  }
}