.fast-recall{
  background: var(--dark-blue);
  position: relative;
  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  &__title{
    &_white{
      color: #FFF;
    }

    &_blue{
      color: var(--primary-blue);
    }
  }

  &__subtitle{
    color: #FFF;
  }

  &__phone{
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    color: #FFF;
  }

  &__whatsapp-logo{
    margin-right: 14px;
    display: flex;
    margin-top: 2px;
  }
}

@include respond-up('s-large') {
  .fast-recall{
    &:before{
      pointer-events: none;
      top: 0;
      bottom: 0;
      right: 0;
      background-position: right;
      background-image: url('../images/base/fast-recall-large.png');
    }

    &__wrap{
      padding: 80px 0 90px 0;
    }

    &__title{
      margin-bottom: 20px;
    }

    &__subtitle{
      margin-bottom: 87px;
    }

    &__bottom{
      display: flex;
    }

    &__contact-wrap{
      display: flex;
    }

    &__button{
      margin-right: 60px;
    }

    &__phone{
      font-size: 25px;
      margin-bottom: 2px;
    }
  }
}

@include respond-down('medium') {
  .fast-recall{
    padding-top: 250px;
    padding-bottom: 40px;
    &:before{
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      background-position: right top;
      background-image: url('../images/base/fast-recall-small.png');
    }

    &__title{
      margin-bottom: 14px;
    }

    &__subtitle{
      margin-bottom: 40px;
    }

    &__button{
      margin-bottom: 20px;
      display: flex;
      max-width: 342px;
      justify-content: center;
    }

    &__contact-wrap{
      display: flex;
    }
    &__phone{
      font-size: 27px;
      margin-bottom: 2px;
    }
  }
}