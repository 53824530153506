.drop-menu{
  box-shadow: 0px 16px 13px rgba(0, 0, 0, 0.2);
  background: #FFFFFF;
  overflow-y: scroll;

  &__link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px var(--grid-spacer-and-indent) 16px var(--grid-spacer-and-indent);
  }

  &__block-content{
    padding: 10px var(--grid-spacer-and-indent) 30px var(--grid-spacer-and-indent);
    display: flex;
    flex-direction: column;
    a{
      padding: 7px 0;
    }
  }

  &__block{
    transition: height .4s;
    height: 0;
    overflow: hidden;
  }

  &__icon{
    display: flex;
    svg{
      transition: transform .3s;
    }
  }

  &__element{
    border-bottom: 1px solid var(--grey-line);
    &:first-child{
      border-top: 1px solid var(--grey-line);
    }
    &._opened{
      .drop-menu{
        &__block{
          height: auto;
        }

        &__icon{
          svg{
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__list{
    padding: 23px var(--grid-spacer-and-indent) 33px var(--grid-spacer-and-indent);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  &__nav-link{
    padding: 7px 0;
  }

  &__contact-wrap{
    padding: 0 var(--grid-spacer-and-indent) 16px var(--grid-spacer-and-indent);
  }

  &__time-icon, &__geo-icon{
    display: flex;
    margin-right: 10px;
    svg{
      width: 16px;
      height: 16px;
    }
  }

  &__geo-text, &__time-text{
    color: var(--black-color) !important;
  }

  &__geo-info, &__time-info{
    display: flex;
    align-items: center;
  }

  &__geo-info{
    margin-bottom: 10px;
  }

  &__time-info{
    margin-bottom: 57px;
  }

  &__button{
    display: flex;
  }

  &__recall-link{
    width: 100%;
  }
}