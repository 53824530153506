.footer{
  background: var(--dark-blue);
  &__small-title{
    @extend .text__04body;
    color: var(--white-opacity-40) !important;
  }

  &__nav-title{
    @extend .text__04body;
    color: var(--white-opacity-40) !important;
    &._transparent{
      opacity: 0;
    }
  }

  &__small-value{
    @extend .text__03body;
    color: #FFFFFF !important;
  }

  &__nav-link{
    color: #FFFFFF;
    transition: color .3s;
    padding: 7px 0;
    &:hover{
      color: var(--primary-blue);
    }
  }

  &__email, &__address{
    display: flex;
    flex-direction: column;
  }

  &__list{
    display: flex;
    flex-direction: column;
  }

  &__logo{
    display: flex;
    align-items: center;
    filter: grayscale(1);
  }

  &__logo-text{
    color: var(--white-opacity-40);
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 14px;
    text-transform: uppercase;
  }

  &__payment-title{
    color: #FFFFFF !important;
  }
}

@include respond-up('s-large') {
  .footer{
    padding: 80px 0 40px 0;

    &__contact-wrap{
      grid-column: 1 / 5;
    }

    &__phone-wrap{
      margin-bottom: 20px;
    }

    &__recall-button{
      margin-bottom: 30px;
      display: flex;
      width: 100%;
    }

    &__button{
      width: 265px;
    }

    &__email{
      margin-bottom: 10px;
    }

    &__top{
      margin-bottom: 60px;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    }

    &__menu-wrap{
      grid-column: 6 / 13;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 20px;
    }

    &__nav-title{
      padding-bottom: 14px;
    }

    &__copy-wrap{
      align-items: center;
    }

    &__logo{
      grid-column: 1 / 5;
    }

    &__copyright{
      grid-column: 6 / 9;
    }

    &__policy{
      grid-column: 9 / 12;
    }

    &__payment-wrap{
      padding: 15px;
      background: var(--white-opacity-10);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }

    &__payment-icon{
      display: flex;
      margin-right: 14px;
    }

    &__payment-item{
      display: flex;
      align-items: center;
      margin-right: 20px;
      &:last-child{
        margin-right: unset;
      }
    }

    &__payment-title{
      &_main{
        margin-right: 30px;
      }
    }
  }
}

@include respond-down('medium') {
  .footer{
    padding: 40px 0;
    &__nav-title{
      padding-bottom: 7px;
      &._transparent{
        display: none;
      }
    }

    &__contact-wrap{
      grid-column: 1 / 5;
      margin-bottom: 30px;
    }

    &__phone-wrap{
      margin-bottom: 20px;
    }

    &__recall-button{
      margin-bottom: 20px;
      display: flex;
      width: 100%;
    }

    &__button{
      width: 100%;
    }

    &__email{
      margin-bottom: 10px;
    }

    &__menu-wrap{
      grid-column: 1 / 5;
    }

    &__list{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: var(--grid-spacer);
    }

    &__services, &__add-services{
      margin: 30px 0;
    }

    &__payment-wrap{
      padding: 16px;
      background: var(--white-opacity-10);
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
    }

    &__payment-icon{
      display: flex;
      margin-right: 14px;
    }

    &__payment-item{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: unset;
      }
    }

    &__payment-title{
      &_main{
        margin-bottom: 14px;
      }
    }

    &__logo, &__copyright, &__policy{
      grid-column: 1 / 5;
    }

    &__logo{
      margin-bottom: 14px;
    }

    &__copyright{
      margin-bottom: 10px;
    }
  }
}