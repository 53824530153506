.questions{
  background: #FFF;
  &__title{
    display: block;
  }

  &__block{
    transition: height .4s;
    height: 0;
    overflow: hidden;
  }

  &__element{
    border-bottom: 1px solid var(--grey-line);
    &:first-child{
      border-top: 1px solid var(--grey-line);
    }
    &._opened{
      .questions{
        &__block{
          height: auto;
        }

        &__icon{
          &:after{
            opacity: 0;
          }
        }
      }
    }
  }

  &__link{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__icon{
    display: block;
    border-radius: 4px;
    background: var(--grey-background);
    margin-left: 20px;
    position: relative;

    &:before{
      content: '';
      position: absolute;
      width: 14px;
      height: 2px;
      background: var(--primary-blue);
      left: calc(50% - 7px);
      top: calc(50% - 1px);
    }

    &:after{
      content: '';
      position: absolute;
      height: 14px;
      width: 2px;
      background: var(--primary-blue);
      left: calc(50% - 1px);
      top: calc(50% - 7px);
      transition: opacity .3s;
    }
  }
}

@include respond-up('s-large') {
  .questions{
    padding: 80px 0;
    &__element{
      padding: 20px 0;
    }
    &__title{
      margin-bottom: 30px;
    }

    &__block-content{
      padding-top: 30px;
      padding-bottom: 20px;
    }

    &__icon{
      flex: 48px 0 0;
      width: 48px;
      height: 48px;
    }
  }
}

@include respond-down('medium') {
  .questions{
    padding: 40px 0;
    &__title{
      margin-bottom: 14px;
    }

    &__element{
      padding: 14px 0;
    }

    &__block-content{
      padding-top: 20px;
      padding-bottom: 6px;
    }

    &__icon{
      flex: 40px 0 0;
      width: 40px;
      height: 40px;
    }
  }
}