.long-recall{
  &__badge{
    background: var(--grey-background);
    padding: 14px 20px;
  }

  &__policy{
    .policy-checkbox{
      label{
        color: var(--grey-text-color) !important;
      }
    }
  }
}

@include respond-up('s-large') {
  .long-recall{
    height: 602px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('../images/base/long-recall-back.png');
      z-index: -1;
    }

    &__wrap{
      display: flex;
      align-items: center;
      height: 100%;
    }
    &__inner{
      background: #FFFFFF;
      border-radius: 8px;
      width: 100%;
      height: 378px;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        pointer-events: none;
        bottom: 0;
        right: 0;
        width: 100%;
        background-repeat: no-repeat;
        background-image: url('../images/base/long-recall-inner.png');
        z-index: 0;
        background-position: right;
        height: 428px;
        /* z-index: 234; */
        top: -50px;
        background-size: contain;
        border-radius: 8px;
        overflow: hidden;
      }
    }

    &__text-container{
      position: absolute;
      z-index: 1;
      padding: 43px 0 64px 47px;
      width: var(--grid-column6);
    }

    &__title{
      margin-bottom: 20px;
      white-space: nowrap;
    }

    &__subtitle{
      margin-bottom: 60px;
      max-width: 503px;
    }

    &__badge{
      border-radius: 4px 0 0 4px;
      width: 309px;
      position: absolute;
      right: 0;
      bottom: 23px;
    }
  }
}

@include respond-down('medium') {
  .long-recall{
    height: auto;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('../images/base/long-recall-back.png');
      z-index: -1;
    }

    &__wrap{
      display: flex;
      align-items: center;
      height: 100%;
    }
    &__inner{
      margin: 53px 0 20px 0;
      background: #FFFFFF;
      border-radius: 8px;
      width: 100%;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        pointer-events: none;
        top: -30px;
        right: 0;
        width: 100%;
        height: 249px;
        background-repeat: no-repeat;
        background-image: url('../images/base/long-recall-inner-small.png');
        z-index: 0;
        background-position: top right;
        /* z-index: 234; */
        background-size: contain;
        border-radius: 8px;
        overflow: hidden;
      }
    }

    &__text-container{
      padding: 235px 14px 20px 14px;
    }

    &__title{
      margin-bottom: 14px;
    }

    &__subtitle{
      margin-bottom: 20px;
    }

    &__badge{
      border-radius: 4px 0 0 4px;
      position: absolute;
      right: 0;
      top: 130px;
      width: fit-content;
      padding: 12px 16px;
    }
  }
}