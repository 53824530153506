.modal-form {
  &__fields {
    display: flex;
  }

  &__bottom {
    flex-direction: column;
    display: flex;
  }

  &__button{
    border: unset;
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
  }

  &__field{
    input{
      background: var(--grey-background);
      border: none;
      border-radius: 6px;
      width: 100%;
    }

    label{
      display: none;
    }
  }

  .errors{
    margin-top: 5px;
    li{
      font-size: 12px;
      color: darkred;
    }
  }
}

@include respond-up('s-large') {
  .modal-form {
    &__form-wrap {
      margin-top: 27px;
    }

    &__title{
      margin-bottom: 20px;
    }

    &__subtitle{
      margin-bottom: 30px;
      max-width: 374px;
    }

    &__field {
      flex: 0 1 100%;
      input{
        height: 60px;
        padding: 18px 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #50515B;
        &::placeholder{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #50515B;
        }
      }

      &:nth-child(2){
        margin: 10px 0;
      }
    }

    &__bottom {
    }

    &__policy {
      width: 250px;
    }

    &__button-wrap {
      flex: 0 0 auto;
      button{
        width: 100%;
      }
    }

    &__button{
      font-size: 15px;
    }
  }
}

@include respond-down('medium') {
  .modal-form {

    &__title{
      margin-bottom: 14px;
    }

    &__subtitle{
      margin-bottom: 20px;
    }

    &__form-wrap {
      margin-top: 20px;
    }

    &__fields {
      flex-direction: column;
    }

    &__field {
      &:first-child{
        margin-bottom: 6px;
      }
      input{
        height: 49px;
        padding: 13px 16px;
      }
    }

    &__policy {
      //order: 3;
      margin-top: 12px;
    }

    &__button-wrap {
      //order: 2;

      .button {
        width: 100%;
      }
    }

    &__button{
      font-size: 13px;
    }
  }
}