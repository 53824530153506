.about{
  &__image-container{
    position: relative;
    height: 300px;
  };

  &__picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('s-large') {
  .about{
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr 500px;
    grid-column-gap: 50px;
    &__breadcrumbs{
      padding-top: 178px;
    }
    &__text{
      display: flex;
      flex-direction: column;
    }
    &__title{
      display: block;
      padding-bottom: 30px;
    }

    &__image-container{
      margin-top: 87px;
    }
  }
}

@include respond-down('medium') {
  .about{
    padding: 30px 0;
    display: flex;
    flex-direction: column-reverse;

    &__image-container{
      margin-bottom: 30px;
      height: 50vw;
    }

    &__title{
      margin-bottom: 20px;
    }
  }
}